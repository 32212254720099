import * as React from "react"
import { Link as GatsbyLink, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { StaticImage } from "gatsby-plugin-image"
import {
  Badge,
  Box,
  Flex,
  Link,
  Text,
  SimpleGrid,
  Button,
} from "@chakra-ui/react"
import { Container } from "../../components/Container"
import { Heading } from "../../components/Heading"
import { Layout } from "../../layouts/default"
import langKeys from "../../locales/lang.json"

// Sanity helpers
import { getFluidProps } from "../../lib/sanity"
import { FormulationChallengeBlock } from "../../components/Blocks/FormulationChallengeBlock"
import { Helmet } from "react-helmet"
import { DynamicLink } from "../../components/DynamicLink"
import { useLocale } from "../../context/locale/LocaleProvider"

const ArchivedExpertEvents = ({ data, pageContext }) => {
  const { locale } = pageContext
  const { state } = useLocale()
  const stateLocale = state?.locale || locale

  if (data) {
    const filteredData = data.allSanityEvent.edges.filter(edge => {
      return edge.node.isPastEvent
    })

    return (
      <Layout>
        <Helmet>
          <meta name="title" content="Expert Events" />
          <meta
            name="description"
            content="Upcoming events and opportunities to talk to Micropore personnel in person; or to see a live demonstration Micropore's unique technology."
          />
        </Helmet>

        <Box height="500px" position="relative" mb={-24} bg="blue.100">
          <StaticImage
            src="../../images/events-bg.jpg"
            alt=""
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              zIndex: 0,
            }}
          />
        </Box>

        <Container px={0} position="relative" zIndex={10}>
          <Flex
            flexDir={{ base: "column", lg: "row" }}
            justifyContent={{ lg: "space-between" }}
          >
            <Box
              bg="white"
              width={{ md: "50%" }}
              p={{ base: 4, md: 12 }}
              pb={0}
            >
              <Heading fontSize="40px" mb={2}>
                {langKeys["prev-events"].title[stateLocale]}
              </Heading>
              <Text>{langKeys["prev-events"].subtitle[stateLocale]}</Text>
            </Box>

            <Box
              pb={{ sm: 8, lg: 0 }}
              pl={{ sm: 4, md: 12, lg: 0 }}
              pr={{ sm: 4, md: 12, lg: 12 }}
              pt={{ sm: 6, md: 0, lg: 8 }}
              pos="relative"
              display="flex"
              justifyContent="flex-end"
              top={{ lg: "96px" }}
              width="100%"
              maxWidth={{ base: "100%", lg: "640px" }}
              color="blue.600"
              fontWeight="600"
              fontSize={20}
            >
              <Link
                as={DynamicLink}
                color="blue.600"
                fontWeight="600"
                fontSize={20}
                to="/making-products-better/expert-events"
              >
                {langKeys["prev-events"].link[stateLocale]}
              </Link>
            </Box>
          </Flex>

          <Container bg="white" mb={20} mt={{ sm: 8 }}>
            <SimpleGrid columns={{ base: 1, lg: 3 }} gap={16}>
              {filteredData.length > 0 &&
                filteredData.map(article => {
                  return (
                    <Box
                      pb={2}
                      shadow="lg"
                      maxW={{ lg: "sm" }}
                      borderWidth="1px"
                      overflow="hidden"
                    >
                      <Link
                        as={DynamicLink}
                        to={`/useful-resources/expert-events/${article.node.slug.current}`}
                        style={{ textDecoration: "none" }}
                      >
                        {article.node.featureImage?.asset ? (
                          <GatsbyImage
                            image={getFluidProps({
                              id: article.node.featureImage.asset.id,
                            })}
                            style={{ height: 200 }}
                          />
                        ) : (
                          <Box height="200px" />
                        )}

                        <Box p="4">
                          <Heading
                            as="h3"
                            fontSize={20}
                            bg="white"
                            mt="-58px"
                            position="relative"
                            padding="16px 16px 10px"
                            mb={0}
                          >
                            {article.node.title[locale]}
                          </Heading>

                          {article.node.involvement && (
                            <Box padding="0 16px 10px" mb={4}>
                              <Badge colorScheme="green">
                                {article.node.involvement}
                              </Badge>
                            </Box>
                          )}

                          <Box px={4}>
                            <Text mb={5} textTransform="capitalize">
                              {locale === "en"
                                ? article.node.eventStart
                                : article.node.eventStartFr}
                              {article.node.eventEnd &&
                                ` - ${
                                  locale === "en"
                                    ? article.node.eventEnd
                                    : article.node.eventEndFr
                                }`}
                            </Text>

                            <Button bg="blue.100" _hover={{ bg: "brand.100" }}>
                              {langKeys["read-more"][stateLocale]}
                            </Button>
                          </Box>
                        </Box>
                      </Link>
                    </Box>
                  )
                })}
            </SimpleGrid>
          </Container>

          <Container
            bg="white"
            pt={4}
            borderTopWidth={2}
            borderTopColor="gray.300"
          >
            <FormulationChallengeBlock locale={locale} />
          </Container>
        </Container>
      </Layout>
    )
  }

  return <Text>There was a problem. Please reload and try again.</Text>
}

export const query = graphql`
  query ArchivedEventsQuery {
    allSanityEvent(sort: { fields: eventStart, order: DESC }) {
      edges {
        node {
          id
          title {
            en
            fr
            es
            it
            de
            nl
            ko
            ja
          }
          involvement
          isPastEvent
          eventStart(formatString: "dddd Do MMMM YYYY")
          eventEnd(formatString: "dddd Do MMMM YYYY")
          eventStartFr: eventStart(
            formatString: "dddd Do MMMM YYYY"
            locale: "fr"
          )
          eventEndFr: eventEnd(formatString: "dddd Do MMMM YYYY", locale: "fr")
          _rawAbstract
          slug {
            current
          }
          featureImage {
            asset {
              id
            }
          }
        }
      }
    }
  }
`

export default ArchivedExpertEvents
